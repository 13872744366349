import React from "react"
import Emoji from "./emoji"
import MountainRange from "-!svg-react-loader?props[]=className:w-full!../assets/svg/vector-mountain-range.svg";

const TheEnd = () => (
    <div className="bg-primary-bg">
        <p className="p-6 font-bold text-center lg:pt-12 lg:text-lg">
            Made with <Emoji symbol="❤️" /> by Rentware.<br/>With people from <Emoji symbol="🇵🇱" /> <Emoji symbol="🇩🇪" /> <Emoji symbol="🇦🇺" />
        </p>
        <MountainRange />
    </div>
)
    
export default TheEnd